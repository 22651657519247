import React, { useState, useEffect, FormEvent } from 'react';

import DeliveryManagement from './DeliveryManagement';
import SalesManagement from './SalesManagement';
import StockManagement from './StockManagement';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { checkAdmin } from '../../utils/auth/TokenMethod';
import '../../../styles/management/management.sass'
import { Navigate } from 'react-router-dom';
import LoadingScreen from '../loading/LoadingScreen';

export interface FormValues {
  products: {
    [productName: string]: {
      [productVariationId: number]: Product[];
    };
  };
  postcode: string;
  address: string;
}

export interface Product {
  id: number;
  rarity_name: string;
  name: string;
  note: string;
}


const Management: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    const checkUser = async() =>{
      try {
        const response = await checkAdmin();
        // 管理者以外の場合は必ず例外が発生する
        setIsAdmin(true);
        setLoading(false);
      } catch {
        setIsAdmin(false);
        setLoading(false);
      }
    }
    checkUser();
  }, []);
  
  if (loading) {
    return <LoadingScreen />;
  }

  if(!isAdmin){
    return <Navigate to="/" />;
  }

  return (
    <div className="mainSection">
      <Tabs
        id="controlled-tab-management"
        defaultActiveKey="delivery"
        className="mb-3"
        fill
      >
        <Tab eventKey="delivery" title="発送管理">
          <DeliveryManagement />
        </Tab>
        <Tab eventKey="stock" title="在庫管理">
          <StockManagement />
        </Tab>
        <Tab eventKey="sales" title="売上管理">
          <SalesManagement />
        </Tab>
      </Tabs>
    </div>
  );
};

export default Management; 