import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export interface ProductVariation {
  id: number;
  name: string;
  rarity: string;
  back_number: string;
  team_name: string;
  product_id: string;
}

export interface Delivery {
  id: string;
  recipient_name: string;
  email: string;
  phone_number: string;
  address: string;
  postcode: string;
  status: number;
  create: string;
  update: string;
  deliveryproductvariation_set: {
    [productName: string]: ProductVariation[];
  };
}

export const searchDeliveryData = async (email: string, searchStartTime: string, searchEndTime: string): Promise<Delivery[]> => {
  if (searchStartTime === "" && searchEndTime !== "") {
    searchStartTime = searchEndTime;
  } else if (searchEndTime === "" && searchStartTime !== "") {
    searchEndTime = searchStartTime;
  }

  const postData = { searchStartTime: searchStartTime, searchEndTime: searchEndTime, email: email };
  // 現在のユーザーのデータを取得
  const response = await axios.post(`${apiUrl}/maintenance/searchDeliveryAdmin/`, postData, { withCredentials: true });
  return response.data;
}