import React, { useContext } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import AuthForm from '../mainSectionComponents/auth/AuthForm';
import PasswordResetRequestForm from '../mainSectionComponents/auth/PasswordResetRequestForm';
import PasswordResetForm from '../mainSectionComponents/auth/PasswordResetForm';
import Top from '../mainSectionComponents/Top';
import RegisterForm from '../mainSectionComponents/register/RegisterForm';
import GachaPlay from '../mainSectionComponents/gacha/GachaPlay';
import Delivery from '../mainSectionComponents/delivery/Delivery';
import Ticket from '../mainSectionComponents/ticket/Ticket';
import Error404Component from '../mainSectionComponents/error/Error404'
import AuthContext from '../utils/auth/AuthContext';
import Team from '../mainSectionComponents/teams/Team';
import LoadingScreen from '../mainSectionComponents/loading/LoadingScreen';
import Transaction from '../mainSectionComponents/term/Transaction';
import Privacy from '../mainSectionComponents/term/Privacy';
import Term from '../mainSectionComponents/term/Term';
import Album from '../mainSectionComponents/album/Album';
import Management from '../mainSectionComponents/management/Management';
import Logout from '../mainSectionComponents/auth/Logout';
import '../../styles/_reset.sass';
import { PrivateRoute } from '../utils/route/PrivateRoute';
import { PublicRoute } from '../utils/route/PublicRoute';

const MainSection: React.FC = () => {
  const { authentication } = useContext(AuthContext);
  const navigate = useNavigate();

  // TODO 認証済みのみページや管理者用ページにアクセスさせないようにするための処理追加
  return (
    <div className='body'>
      <Routes>
        {/* 非認証ページ */}
        <Route path="/login" element={<PublicRoute><AuthForm /></PublicRoute>} />
        <Route path="/register" element={<PublicRoute><RegisterForm /></PublicRoute>} />
        <Route path="/password-reset" element={<PublicRoute><PasswordResetRequestForm /></PublicRoute>} />
        <Route path="/password-reset/:uid/:token" element={<PublicRoute><PasswordResetForm /></PublicRoute>} />
        {/* 共通ページ */}
        <Route path="/" element={<Top />} />
        <Route path="/loading" element={<LoadingScreen />} />
        <Route path="/transaction" element={<Transaction />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/term" element={<Term />} />
        <Route path="/:team" element={<Team />} />
        <Route path="/:team/:product_code" element={<GachaPlay />} />
        <Route path="/404" element={<Error404Component />} />
        <Route path="/logout" element={<Logout reason="" />} />
        {/* 認証ページ */}
        <Route path="/delivery" element={<PrivateRoute><Delivery /></PrivateRoute>} />
        <Route path="/ticket" element={<PrivateRoute><Ticket /></PrivateRoute>} />
        <Route path="/album" element={<PrivateRoute><Album /></PrivateRoute>} />
        {/* 管理者限定ページ */}
        <Route path="/management" element={<Management />} />
      </Routes>
    </div>
  );
}

export default MainSection;