import React, { useState } from 'react';
import axios, { AxiosError } from 'axios';
import { validatePasswords } from '../../utils/validate/Validate';
import { Link, useParams } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const PasswordResetForm: React.FC = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState("first");
  const { uid = "default" } = useParams();
  const { token = "default" } = useParams();

  const submit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();
    if (message !== "") {
      // バリデーションエラー中なので通常はここは通らない
      return;
    }
    try {
      const postData = { uidb64: uid, token: token, password: password }
      const response = await axios.post(`${apiUrl}/password_reset_confirm/`,
        postData
        , { withCredentials: true });
      if (response.status === 200) {
        setStatus("complete");
      } else {
        setMessage("通信に失敗しました。トップページに戻って再度お試しください。");
      }
    } catch (error) {
      setMessage("エラーが発生しました。ページを読み込みなおして再度お試しください。");
    }
  };

  let content;
  switch (status) {
    case 'first':
      content = (
        <>
          <p className="topTitle">パスワード再設定</p>
          <div className="px-1 mainSection">
            <p className="fs-caption my-2">
              新しいパスワードを入力し、「パスワードを設定する」ボタンを押してください。
            </p>
            <form onSubmit={submit}>
              <p className="title">パスワード</p>
              <input
                className="form-control"
                type="password"
                name="password"
                value={password}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setPassword(newValue);
                  setMessage(validatePasswords(newValue, passwordConfirm));
                }} />
              <p className="fs-caption mt-1">
                ※8〜16字の英数字で入力してください。
              </p>
              <p className="title">パスワード（確認）</p>
              <input
                className="form-control"
                type="password"
                name="passwordConfirm"
                value={passwordConfirm}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setPasswordConfirm(newValue);
                  setMessage(validatePasswords(password, newValue));
                }} />
              <input className='btn btn-primary min-w-200 mx-auto mt-3 mb-6 px-2 py-1' disabled={message ? true : false} type="submit" value="パスワードを設定する" />
              <div className='text-danger'>{message}</div>
            </form>
          </div>
        </>
      );
      break;
    case 'complete':
      content = (
        <>
          <p className="topTitle">パスワード設定完了</p>
          <div className="px-1 mainSection">
            <p className="fs-description mb-3">
              パスワード再設定が完了しました。
            </p>
            <p className="fs-description">
              「ログインする」ボタンにてログイン画面に戻り<br />
              新しいパスワードでログインしてください。
            </p>
            <Link className="btn btn-primary min-w-200 mx-auto mt-3 mb-2 px-2 py-1" to="/login">ログインする</Link>
          </div>
        </>
      );
      break;
    default:
      content = <div>Unknown status</div>;
      break;
  }

  return (
    <>
      {content}
    </>
  );
};

export default PasswordResetForm; 