import { useEffect, useState } from 'react';
import { LoadingScreen } from '../loading/LoadingScreen'
import Table from 'react-bootstrap/Table';
import { IconContext } from 'react-icons'
import { BsFillCreditCard2FrontFill } from 'react-icons/bs';
import { validateHandler, validateCreditCardNumber, validateCreditCardName, validateCreditCardYear, validateCreditCardMonth, validateSecuritycode } from '../../utils/validate/Validate';
import axios from 'axios';
// import Multipayment from 'https://stg.static.mul-pay.jp/ext/js/token.js'
// import 'https://stg.static.mul-pay.jp/ext/js/token.js'


const apiUrl = process.env.REACT_APP_BACKEND_API_URL;
const tokenApiUrl = process.env.REACT_APP_GMO_TOKEN_URL;
const price: number = Number(process.env.REACT_APP_TICKET_PRICE);
const shopId = process.env.REACT_APP_GMO_SHOP_ID;

type TokenResponse = {
  tokenObject: {
    maskedCardNo: string;
    toBeExpiredAt: string;
    isSecurityCodeSet: boolean;
    token: string;
  };
  resultCode: string;
};

declare const Multipayment: {
  init: (key: string) => void;
  getToken: (
    cardInfo: {
      cardno: string;
      expire: string;
      securitycode: string;
      holdername: string;
    },
    callback: (response: TokenResponse) => void
  ) => void;
};

// Promiseを返す新しい関数
function getTokenPromise(cardInfo: {
  cardno: string;
  expire: string;
  securitycode: string;
  holdername: string;
}): Promise<TokenResponse> {
  return new Promise((resolve, reject) => {
    Multipayment.getToken(cardInfo, (response) => {
      resolve(response);
    });
  });
}
// kokomade

const doPurchase = (cardno: string, year: string, month: string, holdername: string, securitycode: string, orderId: string, accessID: string, accessPass: string, setStatus: React.Dispatch<React.SetStateAction<string>>, setErrorMessage: React.Dispatch<React.SetStateAction<string>>, setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
  setLoading(true);
  if (typeof shopId === 'string') {
    Multipayment.init(shopId);
    getTokenPromise({
      cardno: cardno,
      expire: year + month,
      securitycode: securitycode,
      holdername: holdername,
    }).then(async (response) => {
      // トークン発行に成功
      if (response.resultCode === "000") {
        const postData = { orderId: orderId, token: response.tokenObject.token, accessID: accessID, accessPass: accessPass };
        const execResponse = await axios.post(`${apiUrl}/execByCreditcard/`, postData, { withCredentials: true });
        if (execResponse.status === 200) {
          setErrorMessage("");
          setStatus("complete");
        } else{
          setErrorMessage("決済に失敗しました。トップページに戻って再度お試しください。");
        }
      } else if (Number(response.resultCode) <= 500) {
        // response.resultCodeが000以外だとhttpStatusが200以外なため、catchに入るのでここは通らないと思われる
        setErrorMessage("カード情報の認証に失敗しました。入力内容を再度ご確認ください。");
      } else {
        // response.resultCodeが000以外だとhttpStatusが200以外なため、catchに入るのでここは通らないと思われる
        setErrorMessage("通信に失敗しました。しばらく時間を置いてから再度お試しください。");
      }
      setLoading(false)
    }).catch((error) => {
      if(error.response.status === 401){
        // TODO 残高不足などの分岐追加
        setErrorMessage("決済に失敗しました。入力内容を再度ご確認ください。");
      } else{
        setErrorMessage("通信に失敗しました。トップページに戻って再度お試しください。");
      }
      setLoading(false)
    });
    ;
  }
}

type TicketConfirmProps = {
  buyTickets: number,
  orderId: string,
  paymentMethod: string,
  accessID: string,
  accessPass: string,
  setStatus: React.Dispatch<React.SetStateAction<string>>,
}

export const TicketConfirm: React.FC<TicketConfirmProps> = ({ buyTickets, orderId, paymentMethod, accessID, accessPass, setStatus }) => {
  // 現在の年の下二桁を取得
  const currentYear = new Date().getFullYear() % 100;
  // 10年後までの配列を生成
  const years: string[] = Array.from({ length: 11 }, (_, i) => (currentYear + i).toString().padStart(2, '0'));

  // カード情報
  const [cardno, setCardno] = useState("");
  const [year, setYear] = useState(years[0]);
  const [month, setMonth] = useState("01");
  const [holdername, setHoldername] = useState("");
  const [securitycode, setSecuritycode] = useState("");

  // 各フォームのエラー設定
  const [cardnoError, setCardnoError] = useState("この項目は入力必須です");
  const [yearError, setYearError] = useState("");
  const [monthError, setMonthError] = useState("");

  const [holdernameError, setHoldernameError] = useState("この項目は入力必須です");
  const [securitycodeError, setSecuritycodeError] = useState("この項目は入力必須です");

  const [errorMessage, setErrorMessage] = useState("");

  const handleCreditCardNumberChange = validateHandler(setCardno, setCardnoError, validateCreditCardNumber);
  const handleYearChange = validateHandler(setYear, setYearError, validateCreditCardYear);
  const handleMonthChange = validateHandler(setMonth, setMonthError, validateCreditCardMonth);
  const handleCreditCardNameChange = validateHandler(setHoldername, setHoldernameError, validateCreditCardName);
  const handleSecuritycodeChange = validateHandler(setSecuritycode, setSecuritycodeError, validateSecuritycode);


  const [loading, setLoading] = useState(true);
  const [errorFlag,setErrorFlag] = useState(false);

  useEffect(() => {
    // 画面上部へスクロール
    window.scrollTo(0, 0);
    const script = document.createElement('script');
    // TODO:tokenApiUrlがundefinedだったときの処理を追加する
    if (typeof tokenApiUrl === 'string') {
      script.src = tokenApiUrl;
      script.async = true;
      script.onload = () => {
        setLoading(false);
      };
      document.body.appendChild(script);
    }
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  },[]);

  const handlePurchase = () => {
    doPurchase(cardno, year, month, holdername, securitycode, orderId, accessID, accessPass, setStatus, setErrorMessage,setLoading)
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <p className="topTitle">購入内容のご確認</p>
      <div className='mainSection'>
        <p className="fs-description p-1">購入内容をご確認いただき、ページ下部の「購入する」ボタンを押してください。</p>
        <div className="p-1 pb-3 border-bottom">
          <p className="fs-subtitle">購入商品</p>
          <Table striped bordered className='align-middle border'>
            <tbody>
              <tr>
                <td className="w-35 text-center">商品名</td>
                <td>
                  <img src="image/ticket.png" width="100" height="100" />
                  <p>
                    商品購入・発送用<br />
                    チケット
                  </p>
                </td>
              </tr>
              <tr>
                <td className="w-35 text-center">購入枚数</td>
                <td>{buyTickets}枚</td>
              </tr>
              <tr>
                <td className="w-35 text-center">ご請求額<br />
                  合計（税込み）</td>
                <td>{price * buyTickets}円</td>
              </tr>

            </tbody>
          </Table>
        </div>
        <div className="p-1 pb-4 border-bottom">
          <p className="fs-subtitle my-3">お支払い情報</p>
          <div className="p-1 border">
            <div className="d-flex">
              <h2 className='m-0 ms-2'><BsFillCreditCard2FrontFill /></h2>
              <p className="fs-body d-flex align-items-center m-0 ms-2">{paymentMethod}</p>
            </div>
            <form id="getTokenForm" className="tokenForm">
              <div className="px-2 pb-3 border-bottom">
                <p className="fs-subtitle my-2">カード番号<span className="badge bg-danger ms-2 align-text-top">必須</span></p>
                <input className="form-control" type="text" name="cardno" id="cardno" defaultValue="" onChange={handleCreditCardNumberChange} />
                <p className="text-danger my-2" id="error_cardno">{cardnoError}</p>
              </div>
              <div className="px-2 pb-3 border-bottom">
                <p className="fs-subtitle my-2">カード有効期限<span className="badge bg-danger ms-2 align-text-top">必須</span></p>
                <div className="flex-between">
                  <div className="w-40">
                    <p className="fs-topic mb-2">月</p>
                    <select className="form-control" name="expire_month" id="expire_month" defaultValue="01" onChange={handleMonthChange}>
                      {
                        Array.from({ length: 12 }, (_, i) => i + 1).map(month =>
                          <option key={month} value={month < 10 ? `0${month}` : month}>
                            {month < 10 ? `0${month}` : month}
                          </option>
                        )
                      }
                    </select>
                    <p className="text-danger" id="error_month">{monthError}</p>
                  </div>
                  <div className="w-40">
                    <p className="fs-topic mb-2">年</p>
                    <select className="form-control" name="expire_year" id="expire_year" defaultValue={years[0]} onChange={handleYearChange}>
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year} {/* 年が一桁の場合は0を先頭に追加 */}
                        </option>
                      ))}
                    </select>
                    <p className="text-danger" id="error_year">{yearError}</p>
                  </div>
                </div>
              </div>
              <div className="px-2 pb-3 border-bottom">
                <p className="fs-subtitle my-2">カード名義人<span className="badge bg-danger ms-2 align-text-top">必須</span></p>
                <input className="form-control" type="text" name="holdername" id="holdername" defaultValue="" onChange={handleCreditCardNameChange} />
                <p className="text-danger my-2" id="error_holdername">{holdernameError}</p>
              </div>
              <div className="px-2 pb-3">
                <p className="fs-subtitle my-2">セキュリティコード<span className="badge bg-danger ms-2 align-text-top">必須</span></p>
                <input className="form-control" type="text" name="securitycode" id="securitycode" defaultValue="" onChange={handleSecuritycodeChange} />
                <p className="text-danger my-2" id="error_securitycode">{securitycodeError}</p>
              </div>
            </form>
          </div>
        </div>
        {/* 加盟店購入フォーム */}
        <form id="purchaseForm" action="{{ url('/ticket/complete/credit') }}" method="post">
          <input type="hidden" name="accessId" defaultValue="{{ $result['AccessID'] }}" />
          <input type="hidden" name="accessPass" defaultValue="{{ $result['AccessPass'] }}" />
          <input type="hidden" name="orderId" defaultValue="{{ $result['OrderID'] }}" />
          <input type="hidden" name="number" defaultValue="{{ $number }}" />
          <input type="hidden" defaultValue="" id="token" name="token" />
        </form>
        <form>
          <p className="text-danger my-2 fs-4 text-center" id="error_cardno">{errorMessage}</p>
          <input className="btn btn-primary w-250 mx-auto my-2 px-2 py-1 mt-3" type="button" defaultValue="購入する" onClick={handlePurchase} />
        </form>
        <button className="btn btn-secondary w-250 mx-auto my-2 px-2 py-1" onClick={() => setStatus("first")}>購入内容を編集する</button>
        <p className="fs-description m-3">
          「購入する」ボタンを押してご購入いただくことで、お客様は当サイトの<a href="/term">利用規約</a>、<a href="/privacy">個人情報についての規約</a>、および当サイト上の販売条件に同意の上、商品をご購入されたことになります。<br />
          ※チケットの反映に時間がかかる場合があります。ご了承ください。
        </p>
      </div>
    </>
  );
};