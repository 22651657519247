import React, { useState, useEffect, FormEvent } from 'react';
import { LoadingScreen } from '../loading/LoadingScreen'
import { getProducts, Product } from '../../utils/getData/Products';
import { getSales } from '../../utils/getData/Sales';
import { Button } from 'react-bootstrap';

const price = process.env.REACT_APP_TICKET_PRICE;

const SalesManagement: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [year, setYear] = useState("2023");
  const [month, setMonth] = useState("01");
  const [salesResult, setSalesResult] = useState("");

  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }

  const fetchSalesData = async () => {
    setLoading(true);
    setSelectedTeam(selectedTeam);
    try {
      const yearMonth = year + month;
      const response = await getSales(selectedTeam, yearMonth);
      setSalesResult(response);
      setLoading(false);
    } catch {
      // TODO エラー時の処理
    }
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="mainSection">
      <div className='d-flex justify-content-around'>
        <div>
          <p className='m-0 ms-1'>チーム</p>
          <select className="form-control w-250" name="team" defaultValue={selectedTeam} onChange={e => setSelectedTeam(e.target.value)}>
            <option value=""></option>
            {
              Object.entries(teamList).map(([key, value], index) => (
                <option key={index} value={key}>{value}</option>
              ))
            }
          </select>
        </div>
        <div>
          <p className='m-0 ms-1'>年</p>
          <select className="form-control w-250" name="year" defaultValue={year} onChange={e => setYear(e.target.value)}>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
        <div>
          <p className='m-0 ms-1'>月</p>
          <select className="form-control w-250" name="month" defaultValue={month} onChange={e => setMonth(e.target.value)}>
            {
              Array.from({ length: 12 }, (_, i) => i + 1).map(month =>
                <option key={month} value={month < 10 ? `0${month}` : month}>
                  {month < 10 ? `0${month}` : month}
                </option>
              )
            }
          </select>
        </div>
      </div>
      {salesResult &&
        <div className='text-center'>{year}年 {month}月 売上：{Number(salesResult) * Number(price)}円</div>
      }
      <Button className='mx-auto' onClick={fetchSalesData}>データ取得</Button>
    </div>
  );
};

export default SalesManagement; 