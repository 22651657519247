import React, { useState, useContext } from 'react';
import axios, { AxiosError } from 'axios';
import { Button } from 'react-bootstrap';
import { validateHandler, validateName, validateEmail, validatePasswords, validatePostcode, validatePrefecture, validateCity, validateApartment, validatePhoneNumber } from '../../utils/validate/Validate';
import Term from '../../subComponents/Term';
import Privacy from '../../subComponents/Privacy';
import '../../../styles/register/register.sass';

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

const RegisterFormComponent: React.FC = () => {
  const [lastName, setLastName] = useState("");
  const [lastNameError, setLastNameError] = useState("この項目は入力必須です");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState("この項目は入力必須です");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("この項目は入力必須です");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("この項目は入力必須です");
  const [postcode, setPostcode] = useState("");
  const [PostcodeError, setPostcodeError] = useState("この項目は入力必須です");
  const [prefecture, setPrefecture] = useState("");
  const [prefectureError, setPrefectureError] = useState("この項目は入力必須です");
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("この項目は入力必須です");
  const [apartment, setApartment] = useState("");
  const [apartmentError, setApartmentError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("この項目は入力必須です");
  const [postError, setPostError] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  // TODO emailがすでに登録済みかを確認するか登録済みアドレスで実行時に帰ってくるエラーをハンドリングする

  const handleLastNameChange = validateHandler(setLastName, setLastNameError, validateName);
  const handleFirstNameChange = validateHandler(setFirstName, setFirstNameError, validateName);
  const handleEmaillChange = validateHandler(setEmail, setEmailError, validateEmail);
  const handlePostcodeChange = validateHandler(setPostcode, setPostcodeError, validatePostcode);
  const handlePrefectureChange = validateHandler(setPrefecture, setPrefectureError, validatePrefecture);
  const handleCityChange = validateHandler(setCity, setCityError, validateCity);
  const handleApartmentChange = validateHandler(setApartment, setApartmentError, validateApartment);
  const handlePhoneNumberChange = validateHandler(setPhoneNumber, setPhoneNumberError, validatePhoneNumber);

  const allFieldsValid = () => {
    return !(
      lastNameError ||
      firstNameError ||
      emailError ||
      passwordError ||
      PostcodeError ||
      prefectureError ||
      cityError ||
      apartmentError ||
      phoneNumberError
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!allFieldsValid()) {
      // 通常はここを通らないので何もしない
      return;
    }

    const userData = {
      last_name: lastName,
      first_name: firstName,
      email: email,
      password: password,
      postcode: postcode,
      prefecture: prefecture,
      city: city,
      building: apartment,
      phone_number: phoneNumber,
    };

    try {
      const response = await axios.post(`${apiUrl}/register/`, userData, { withCredentials: true }).then(result => {
        if (result.status === 201) {
          setRegistrationSuccess(true);
        } else {
          setPostError("登録に失敗ました。時間を空けてもう一度お試しください");
        }
      });

    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error;
        // response.dataの型を明示的に指定
        const data = axiosError.response?.data as { email?: string[] };
        if (data && data.email && data.email.includes("user with this email already exists.")) {
          setPostError("既に登録済みのメールアドレスです");
        } else {
          // console.error('Error:', axiosError.response ? axiosError.response.data : axiosError.message);
        }
      } else {
        // axios以外のエラー
      }
    }
  };

  return (
    <div className="mainContainer">
      {registrationSuccess ? (
        <>
          <p className="topTitle">新規会員登録完了</p>
          <div className="p-3">
            <p className="fs-topic mb-3">{lastName}様</p>
            <p className="fs-description mb-3">
              この度は会員登録ありがとうございます。<br />
              会員登録が完了しました。
            </p>
            {/* TODO メールを送信する */}
            {/* <p className="fs-description mb-3">
              お客様のメールアドレス宛に自動返信の内容確認メールを送信いたしましたので、ご確認ください。
            </p>
            <p className="fs-description mb-3">
              しばらく経ってメールが届かない場合は、メールアドレスをご確認の上、最初から新規会員登録をお試しください。
            </p>
            <p className="fs-description mb-3">
              また、メールアドレスによっては、内容確認メールが「迷惑メールフォルダ」に入ってしまう場合があります。<br />
              迷惑メールフォルダに内容確認メールが届いていないかご確認ください。
            </p> */}
            <Button href="/" className="btn btn-primary w-250 mx-auto my-4 px-2 py-1">トップへ戻る</Button>
          </div>
        </>
      ) : (
        <>
          <p className="topTitle">新規会員登録</p>
          <form className="registerForm contentContainer" onSubmit={handleSubmit}>
            <div className="px-2 pb-3 border-bottom">
              <p className="title">お名前</p>
              <div className="flex-between">
                <div className="w-40">
                  <p className="fs-subtitle">姓</p>
                  <input
                    className="form-control"
                    type="text"
                    name="last_name"
                    placeholder="山田"
                    value={lastName}
                    onChange={handleLastNameChange}
                  />
                  <div className="text-danger">{lastNameError}</div>
                </div>
                <div className="w-40">
                  <p className="fs-subtitle">名</p>
                  <input
                    className="form-control"
                    type="text"
                    name="first_name"
                    placeholder="太郎"
                    value={firstName}
                    onChange={handleFirstNameChange}
                  />
                  <div className="text-danger">{firstNameError}</div>
                </div>
              </div>
            </div>
            <div className="px-2 pb-3 border-bottom">
              <p className="title">メールアドレス</p>
              <input
                className="form-control"
                type="text"
                name="email"
                placeholder="example@gmail.com"
                value={email}
                onChange={handleEmaillChange} />
              <div className="text-danger">{emailError}</div>
              <p className="fs-caption mt-1">
                ※メールのセキュリティ設定等により、弊社からのメールが届かない場合がございます。<br />
                本サイトのドメインからのメールを受け取れるよう、設定をお願いいたします。
              </p>
            </div>
            <div className="px-2 pb-3 border-bottom">
              <p className="title">パスワード</p>
              <input
                className="form-control"
                type="password"
                name="password"
                value={password}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setPassword(newValue);
                  setPasswordError(validatePasswords(newValue, passwordConfirm));
                }} />
              <p className="fs-caption mt-1">
                ※8〜16字の英数字で入力してください。
              </p>
              <p className="title">パスワード（確認）</p>
              <input
                className="form-control"
                type="password"
                name="passwordConfirm"
                value={passwordConfirm}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setPasswordConfirm(newValue);
                  setPasswordError(validatePasswords(password, newValue));
                }} />
              <div className="text-danger">{passwordError}</div>
            </div>
            <div className="px-2 pb-3 border-bottom">
              <p className="title">郵便番号</p>
              <div className="flex-between">
                <input className="form-control w-40"
                  type="text"
                  name="postcode"
                  placeholder="5460034"
                  value={postcode}
                  onChange={handlePostcodeChange} />
              </div>
              <div className="text-danger">{PostcodeError}</div>
              <p className="fs-caption mt-1">
                ※半角数字で入力してください。
              </p>
              <p className="title">都道府県</p>
              <input
                className="form-control"
                type="text"
                name="prefecture"
                value={prefecture}
                onChange={handlePrefectureChange} />
              <div className="text-danger">{prefectureError}</div>
              <p className="title">市区町村</p>
              <input
                className="form-control"
                type="text"
                name="city"
                value={city}
                onChange={handleCityChange} />
              <div className="text-danger">{cityError}</div>
              <p className="title">マンション名<span className="badge bg-secondary ms-1">任意</span></p>
              <input
                className="form-control"
                type="text"
                name="building"
                value={apartment}
                onChange={handleApartmentChange} />
              <div className="text-danger">{apartmentError}</div>
              <p className="fs-caption mt-1">
                ※全角英数字、スペース、記号は使用できません。
              </p>
              <div className="px-2 pb-3 border-bottom">
                <p className="title">電話番号</p>
                <input
                  className="form-control"
                  type="text"
                  name="phone_number"
                  placeholder="09012345678"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange} />
                <div className="text-danger">{phoneNumberError} </div>
                <p className="fs-caption mt-1">
                  ※半角数字で入力してください。
                </p>
              </div>
              <Term />
              <Privacy />
              {!allFieldsValid() && (
                <p className="text-danger text-center">修正が必要な項目があります。</p>
              )}
              {postError !== "" ?
                <>
                  <p className="text-danger text-center">{postError} </p>
                  <p className="text-center"><a className="text-info" href="/password-reset">こちらからパスワードの変更を行ってください。</a></p>
                </>
                :
                <></>}
              <input className="btn btn-primary min-w-200 mx-auto my-4 px-2 py-1" type="submit" disabled={!allFieldsValid()} value="規約に同意して登録する" />
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default RegisterFormComponent; 