import React, { useContext, useState, useEffect } from 'react';

import AuthContext from '../utils/auth/AuthContext';
import { Container, Navbar, Offcanvas, Nav, NavDropdown, NavItem } from 'react-bootstrap';
import LogoutButton from '../subComponents/auth/LogoutButton'
import HowToPlayButton from '../subComponents/howtoplay/HowToPlayButton';

import '../../styles/header.sass';
import { useLocation } from 'react-router-dom';
import getLocationTeam from '../utils/getData/LocationTeam';


const Header: React.FC = () => {
  const { authentication } = useContext(AuthContext);
  const [teamColor, setTeamColor] = useState("default");
  const location = useLocation();

  var teamList: Object;
  if (typeof process.env.REACT_APP_TEAMNAMES === "string") {
    teamList = JSON.parse(process.env.REACT_APP_TEAMNAMES);
  } else {
    teamList = [];
  }


  useEffect(() => {
    const teamName = getLocationTeam(location);
    setTeamColor(teamName);
  });

  return (
    <header>
      <Navbar key="false" expand="false" className={`bg-${teamColor}-primary`}>
        <Container fluid>
          <Navbar.Brand className='fs-body' href='/'><img src={"/logo.png"} alt="/" height="50" /></Navbar.Brand>
          {/* <Navbar.Brand href="/" className='text-white'>Onsite Card</Navbar.Brand> */}
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-false`} className="bg-white" />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-false`}
            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
            placement="end"
          >
            <Offcanvas.Body>
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                  MENU
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {authentication ? (
                  <>
                    <Nav.Link href="/ticket">チケット購入</Nav.Link>
                    <NavDropdown
                      title="オンサイトカードをPLAY"
                      id={`offcanvasNavbarDropdown-expand-false`}
                    >
                      {
                        Object.entries(teamList).map(([key, value], index) => (
                          <div key={index}>
                            <NavDropdown.Item href={`/${key}/`}>{value}</NavDropdown.Item>
                            <NavDropdown.Divider />
                          </div>
                        ))
                      }
                    </NavDropdown>
                    <Nav.Link href="/album">アルバム</Nav.Link>
                    <Nav.Link href="/delivery">発送</Nav.Link>
                    <NavItem><HowToPlayButton width="250" /></NavItem>
                    <NavItem><LogoutButton /></NavItem>
                  </>
                ) : (
                  <>
                    <Nav.Link href="/register">新規登録</Nav.Link>
                    <Nav.Link href="/login">ログイン</Nav.Link>
                    {/* <HeaderIcon icon={BsPersonPlusFill} text="新規登録" link="/register" size={24} />
                    <HeaderIcon icon={BsBoxArrowInRight} text="ログイン" link="/login" size={24} /> */}
                  </>
                )}

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;