import React, { useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import AuthContext from './AuthContext';
import LoadingScreen from '../../mainSectionComponents/loading/LoadingScreen';
import Logout from '../../mainSectionComponents/auth/Logout';
import { refreshToken } from './TokenMethod';
const apiUrl = process.env.REACT_APP_BACKEND_API_URL;


interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authentication, setAuthentication] = useState<boolean>(false);
  const [authErrorFlag, setAuthErrorFlag] = useState<boolean>(false);
  const [authLoadingFlag, setAuthLoadingFlag] = useState<boolean>(true);

  const refreshCookieToken = async () => {
    try {
      setAuthLoadingFlag(true);
      const response = await refreshToken();
      if (response.access && response.refresh) {
        setAuthentication(true);
      } else {
        setAuthentication(false);
      }
    } catch (error) {
      setAuthentication(false);
    } finally {
      setAuthLoadingFlag(false);
    }
  }

  useEffect(() => {
    refreshCookieToken();
  }, []);

  if (authLoadingFlag) {
    return <LoadingScreen />
  };

  if (authErrorFlag) {
    return <Logout reason="セッションの有効期限が切れました。再度ログインしてください。" />
  }

  return (
    <AuthContext.Provider value={{ authentication, setAuthentication, authErrorFlag, setAuthErrorFlag, authLoadingFlag, setAuthLoadingFlag }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
