import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import getLocationTeam from '../utils/getData/LocationTeam';

const Footer: React.FC = () => {
  const [teamColor, setTeamColor] = useState("default");
  const location = useLocation();
  var copylight: Record<string, string>;
  if (typeof process.env.REACT_APP_COPYLIGHT === "string") {
    copylight = JSON.parse(process.env.REACT_APP_COPYLIGHT);
  } else {
    copylight = {};
  }

  useEffect(() => {
    const teamName = getLocationTeam(location);
    setTeamColor(teamName);
  });

  return (
    <footer className={`p-1 text-white bg-${teamColor}-secondary`}>
      <div className="container p-reset">
        <div className="d-flex">
          <div className="logo">
            <Link className="navbar-brand" to="/">
              {/* <img src="/image/footer-logo.png" width="150" /> */}
            </Link>
          </div>

          <div className="links">
            {/* <p><Link className="a-reset" to="/">カード</Link></p> */}
            {/* <p><Link className="a-reset" to="/">アルバム</Link></p> */}
            {/* <p><Link className="a-reset" to="/">購入</Link></p> */}
            {/* <p><Link className="a-reset" to="/">発送</Link></p> */}
          </div>

          <div className="links">
            <p><Link className="a-reset" to="/term">利用規約</Link></p>
            <p><Link className="a-reset" to="/privacy">個人情報の取扱いについて</Link></p>
            <p><Link className="a-reset" to="/transaction">特定商取引法に基づく表記</Link></p>
          </div>
        </div>
        <p className="copylight">
          {teamColor !== "default" ?
            <>
              ©{copylight[teamColor]}, All Rights Reserved.<br />
              【掲載の記事・写真・イラストなどの無断複写・転載を禁じます】
            </>
            :
            <>
              ©All Rights Reserved.<br />
              【掲載の記事・写真・イラストなどの無断複写・転載を禁じます】
            </>
          }

        </p>

      </div>
    </footer>
  );
}

export default Footer;
