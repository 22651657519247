import React, { useState, useEffect } from 'react';
const baseUrl = process.env.REACT_APP_BASE_URL;

const Term: React.FC = () => {
  return (
    <>
      <p className="topTitle">利用規約</p>
      <div className='mainSection mb-3'>
        <p className="fs-small mb-3">
          本規約は、当社が運営する、本サービスの利用条件を定めるものです。
        </p>
        <p className="fs-body">第1条　定義</p>
        <p className="fs-small mb-3">
          この利用規約において使用する語句について、次に掲げる通り定義します。<br />
          (1) 本規約　この利用規約<br />
          (2) 当社　株式会社PBridge<br />
          (3) 本サービス　第3条に定めるサービス<br />
          (4) 本サイト　{baseUrl}<br />
          (5) 会員　本サービスを利用する方<br />
          (6) 会員情報　会員が当社に開示した会員の属性に関する情報及び会員の取引に関する履歴等の情報<br />
          (7) 知的財産権等　特許権、商標権等の産業財産権、著作権（著作権法第27条及び第28条に規定されている権利を含む）等の知的財産権、その他の権利<br />
          (8) 反社会的勢力　暴力団・暴力団員・暴力団員でなくなったときから５年を経過しない者、暴力団準構成員・暴力団関係企業・総会屋等・社会運動標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者<br />
          (9) 機器等　機器、通信回線ソフトウェアその他一切の手段<br />
          (10) 機密情報　個人情報、顧客情報、企業情報、その他すべての情報
        </p>
        <p className="fs-body">第2条　この利用規約について</p>
        <p className="fs-small mb-3">
          1.本規約は、会員全員に適用されます。<br />
          2.会員は、本規約への同意がない場合には、本サイト及び本サービスをご利用いただくことはできません。<br />
          3.本サービスの会員は、登録の申込時点又は本サービス利用時点で、本規約に同意したものとみなします。<br />
          4.本規約の内容は、必要に応じて変更することがあり、変更内容はメール、本サイトへの掲載、その他の方法で周知します。本規約の内容の変更については、民法548条の4の定めに従います。ご利用の際は最新の利用規約をご覧下さい。<br />
          5.本サービスには、本規約の他に、本サイトに本サービスの利用方法やご注意いただくべき事項が表示されています。これらも実質的には本規約の一部をなすものとします。<br />
          6.本サービスご利用前には本規約を必ずお読みください。<br />
          7.本規約は、定型約款として当社と会員との間の定型取引に適用されるものとします。
        </p>
        <p className="fs-body">第3条　本サービス内容</p>
        <p className="fs-small mb-3">
          1.本サービスは、本サイトにおいてチケットを購入することにより、会員に対してオンサイトカードを提供するものです。別途手続きを経ることにより実物の発送を行うこともできます。<br />
          2.当社は、会員に対して、メールマガジンその他の方法による情報提供(広告を含みます)を行うことができるものとします。会員が情報提供を希望しない場合は、当社所定の方法に従い、その旨を通知して頂ければ、情報提供を停止します。ただし、本サービス運営に必要な情報提供につきましては、会員の希望により停止をすることはできません。

        </p>
        <p className="fs-body">第4条　登録</p>
        <p className="fs-small mb-3">
          1.本サービスの利用を希望する者は、本サービス利用開始にあたって、本人自身の申請により登録することが必要です。代理申請は認められません。<br />
          2.登録申請は、当社が定める方法により、ＩＤ、パスワード、氏名、名称、メールアドレス等の会員情報を通知することにより行います。虚偽の申請は認められません。<br />
          3.登録申請が認められた時点で、会員と当社との間に、本規約を内容とする本サービスの利用契約が成立するものとします。<br />
          4.会員は、登録した内容に変更があった場合、当社が定める方法により会員情報の変更を行わなければなりません。変更しないことにより、会員に不利益が生じても当社は責任を負いません。<br />
          5.当社は、会員が次のいずれかに該当すると判断した場合は、登録を取消すことがあります。<br />
          (1) 当社の定める登録審査基準を満たさない場合<br />
          (2) 未成年、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていない場合<br />
          (3) 登録申請の内容に虚偽があった場合<br />
          (4) 過去に本規約に違反したことがある場合<br />
          (5) その他当社が登録を承認することが適切でないと判断した場合<br />
          6.当社は、前項の行為を行った場合に、その理由を会員に開示する義務を負いません。
        </p>
        <p className="fs-body">第5条　パスワード等の管理</p>
        <p className="fs-small mb-3">
          1.会員は、ＩＤ及びパスワードを、第三者に漏洩しないよう、自己の責任において厳重に管理して下さい。定期的にパスワードを変更することをおすすめします。<br />
          2.ＩＤ及びパスワードは会員本人のみが使用できるものとし、第三者へ譲渡、貸与等をすることは禁止します。<br />
          3.ＩＤ及びパスワードを利用して行われた行為の責任は、そのＩＤを保有している会員の責任とみなし、当社は一切の責任を負わないものとします。<br />
          4.万一、許可無く自分のＩＤが利用された場合、又はＩＤならびにパスワードが第三者に漏洩してしまった場合は、ただちに当社までご連絡下さい。
        </p>
        <p className="fs-body">第6条　利用方法</p>
        <p className="fs-small mb-3">
          1.会員は、本サービスを本規約に従い、民法、商法、個人情報保護法、著作権法その他法令を遵守して利用して下さい。<br />
          2.会員は、本サービスを利用したことに関する一切の責任を負います。当社は、会員の本サービス利用につき、特に定める場合を除き、一切の責任を負いません。
        </p>
        <p className="fs-body">第7条　禁止事項等</p>
        <p className="fs-small mb-3">
          1.会員は、以下の各号の行為は禁止されます。<br />
          (1) ID、パスワードを不正に利用する行為<br />
          (2) 会員資格、ID及びパスワード、モバガチャ券、商品との引き換え権利を第三者に貸与・譲渡・共有等する行為<br />
          (3) 本サイトにアクセスして情報を改ざんしたり、本サイトに有害なコンピュータプログラムを送信・書き込みしたりするなどして、当社の営業を妨害する行為<br />
          (4) 当社のサーバその他のコンピュータに不正にアクセスする行為<br />
          (5) 当社の取り扱う商品の知的財産権等を侵害する行為<br />
          (6) 虚偽の情報を入力する行為<br />
          (7) 本規約に違反する行為<br />
          (8) 法令に違反する行為又は違反するおそれのある行為<br />
          (9) 第三者の権利、利益、名誉を侵害又は侵害を助長する行為<br />
          (10) 本サービス又はサーバーに対する妨害と当社が判断する行為<br />
          (11) 公序良俗に反する行為<br />
          (12) 反社会的勢力に関与する会員の利用行為<br />
          (13) その他当社が不適切と判断する行為<br />
          2.当社は、会員に前項に定める禁止行為が見受けられた場合、又は当社が当該禁止事項に該当すると判断した場合には、当社の裁量において会員に対しての本サービスの提供を停止・中止、登録の抹消、当社サーバー内のデータ削除等することができるものとします。これによる会員の損害について、当社は一切の責任を負わないものとします。また、当社に損害が生じた場合は、賠償を請求することがあります。
        </p>
        <p className="fs-body">第8条　会員の責任</p>
        <p className="fs-small mb-3">
          1.会員は、本サービスの利用に関し一切の責任を負うものとし、本サービスの利用が、知的財産権等の侵害を行っていないことを当社に保証するものとします。<br />
          2.前項の定めに関わらず、会員の本サービス利用に関して問題が発生した場合は、自己の費用と責任に於いてかかる問題を解決するものとし、当社は一切の責任を負わないものとします。<br />
          3.会員は、自己の責任で本サービスの利用に関するデータのバックアップを取得しなければいけません。当社は、バックアップ未取得によるデータの喪失による責任を負いません。<br />
          4.会員が、本サービスの利用によって当社に対して損害を与えたときは、当該損害（弁護士費用を含みます。）を賠償するものとします。<br />
          5.会員が、本サービスを利用することにより、第三者に対し損害を与えた場合、会員は自己の費用と責任においてこれを賠償するものとし、当社は一切その責任を負わないものとします。この場合において、当社が、第三者から損害賠償請求を受けたときは、会員は、当社に対し、その損害額及び費用（弁護士費用を含みます。）を賠償するものとします。<br />
          6.本サービスの利用には、インターネットに接続する必要があります。会員は、自己の費用と責任において、本サービスを利用するために必要となる機器等を用意するものとします。<br />
          7.当社は、前項に定める機器等の準備、設置、操作に関し、一切保証又は関与せず、会員に対するサポートも行いません。また、当社は、本サービスがあらゆる機器等に適合することを保証するものではありません。<br />
          8.会員は、本サービスを利用する過程で、種々のネットワークや機器を経由することがあることを理解し、接続しているネットワークや機器の種類等によっては、それらに接続したり、それらを通過するために、データや信号等の内容が変更されたりする可能性があることを理解した上で、本サービスを利用するものとします。
        </p>
        <p className="fs-body">第9条　免責</p>
        <p className="fs-small mb-3">
          1.当社は、本サービスの利用により会員に特定の効果が生じることを保証するものではありません。<br />
          2.当社は、本サービスの内容について、充分に注意を払いますが、その正確性・完全性・最新性・有用性等に関して、いかなる保証をするものではありません。本サービスの利用については、会員の自主的な判断によるものとします。<br />
          3.当社は、本サイトで作成したデータの保存、本サイトへのアクセス、本サイト利用によりウィルス等の影響を受けないことについて保証しません。会員は自己責任で本サイトをご利用下さい。<br />
          4.当社は、本サービスに関して会員相互、会員と第三者との間に生じた問題については一切の責任を負いません。<br />
          5.当社は、天災地変等当社の責に帰さない事由により生じた損害、当社の予見の有無を問わず、特別の事情から生じた損害、逸失利益を含む間接損害について賠償責任を負わないものとします。<br />
          6.本規約が消費者契約法の消費者契約に該当する場合には、本規約のうち、当社の損害賠償責任を完全に免責する規定は適用されないものとします。この場合において会員に発生した損害が当社の債務不履行又は不法行為に基づくときは、当社は、当該会員が当社に本サービスの対価として支払った一月分の料金を上限として損害賠償責任を負うものとします。ただし、当社に故意又は重過失がある場合は除きます。
        </p>
        <p className="fs-body">第10条　知的財産権等</p>
        <p className="fs-small mb-3">
          本サイト及び本サービスに関する知的財産権等は当社又は正当な権利を有する第三者に帰属します。会員は、他の会員、当社、その他第三者の権利侵害をすることのないよう利用方法を守って本サービスを利用してください。
        </p>
        <p className="fs-body">第11条　サービスの中断・停止、内容の変更、終了</p>
        <p className="fs-small mb-3">
          1.当社は、本サービスの運営上必要な設備の設置、システムの保守作業、又は天災等の不可抗力のために必要であると判断した場合、会員への事前の通知又は承諾を要せずして、一時的に本サービスを中断・停止できるものとします。<br />
          2.当社は、会員に事前通知をした上で当社の判断により本サービスの一部又は全てについて内容の変更又は終了できるものとします。<br />
          3.前2項の場合に会員に生じた損害について、当社は責めを負わないものとします。ただし、全てのサービスを永続的に終了させた場合は、終了後の本サービスについて料金の支払いを受けている場合は、料金を返還します。
        </p>
        <p className="fs-body">第12条　登録の抹消・退会</p>
        <p className="fs-small mb-3">
          1.会員は、登録を抹消・退会する場合は、当社所定の方法により届出を行わなければなりません。<br />
          2.登録の抹消・退会によりオンサイトカードのチケット残量及び未発送分の獲得商品は失効します。<br />
          3.当社は、登録抹消・退会の理由を問わず、支払われた料金の払い戻しは行いません。<br />
          4.当社は、会員に次の各号のいずれかに該当する事由が生じた場合には、何らの催告なしに直ちに登録を抹消・退会させることができるものとします。<br />
          (1) 登録内容に虚偽があったなど登録申請段階において登録を承認すべきでない事情があったことが判明した場合<br />
          (2) 重大な過失又は背信行為があった場合<br />
          (3) 支払いの停止があった場合、又は仮差押、差押、競売、破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始の申立があった場合<br />
          (4) 手形交換所の取引停止処分を受けた場合<br />
          (5) 公租公課の滞納処分を受けた場合<br />
          (6) その他前各号に準ずるような本規約又は個別契約を継続し難い重大な事由が発生した場合<br />
          5.当社は、会員が前項各号にあたらない本規約のいずれかの条項に違反し、相当期間を定めてなした催告後も、会員の債務不履行が是正されない場合は、登録を抹消・退会させることができるものとします。<br />
          6.再度登録を希望する際は、再度登録手続を行う必要があります。会員は再度の登録手続後、会員情報、従前のデータが引き継がれないことを予め承諾するものとします。<br />
          7.登録抹消・退会後は、次のサービスを利用できなくなります。<br />
          (1) ログイン<br />
          (2) オンサイトカードチケット購入<br />
          (3) オンサイトカードプレイ<br />
          (4) 獲得商品発送手続き<br />
          (5) 会員情報変更<br />
          (6) お届け先の登録・変更・削除<br />
          (7) クレジットカードの登録・削除<br />
          (8) 獲得商品および決済履歴の閲覧<br />
        </p>
        <p className="fs-body">第13条　秘密保持</p>
        <p className="fs-small mb-3">
          1.当社及び会員は、本サービスの利用に関して相手方から開示又は提供された機密情報を善良なる管理者の注意をもって取扱い、事前に書面により相手方の同意を得ることなく、本サービスの目的以外に使用し、又は第三者に開示又は提供してはならないものとします。ただし、個人情報及び顧客情報を除く機密情報のうち、次の各号のいずれかに該当するものについてはこの限りではありません。<br />
          (1) 開示又は提供の前後を問わず公知となった情報<br />
          (2) 開示又は提供された時点において、既に自己が保有している情報<br />
          (3) 開示又は提供によらず、独自に取得した情報<br />
          (4) 機密保持義務を負うことなく正当な権限を有する第三者から合法的に入手した情報<br />
          2.当社は、行政機関又は司法機関から機密情報の開示を要求された場合には、本条の規定にもかかわらず、以下の措置を取った上で当該行政機関又は司法機関に対して当該機密情報を開示することができるものとします。<br />
          (1) 会員に対して当該要求があった旨を遅滞なく書面で通知すること<br />
          (2) 当該機密情報の内、適法に開示が要求されている部分についてのみ開示すること<br />
          (3) 開示する当該機密情報について秘密としての取り扱いが受けられるよう最善をつくすこと<br />
          3.本条の機密情報保持義務は、本サービス利用終了後も存続するものとします。
        </p>
        <p className="fs-body">第14条　会員に関する情報の収集、解析及び取扱い</p>
        <p className="fs-small mb-3">
          1.当社は、個人が特定されない形式で、登録情報、端末情報、その他会員に関する情報を、当社が行う情報配信、統計、その他のサービスに活用し又は第三者に提供するため、利用する場合があります。<br />
          2.当社は、本サービスの利用状況を把握するため、Cookieなどの情報収集モジュールを利用して、本サービスの利用情報を収集します。これら情報収集モジュールについては、各提供者の利用規約及びプライバシーポリシー等をご参照ください。<br />
          3.当社は、Cookieなどの情報収集モジュール利用により生じた損害については責任を負わないものとします。<br />
        </p>
        <p className="fs-body">第15条　プライバシーポリシー</p>
        <p className="fs-small mb-3">
          当社は、適法かつ公正な手段により取得した会員情報を取り扱います。なお、当社は、会員情報を会員へのサービス提供、サービス内容の向上、サービスの利用促進、及びサービスの健全かつ円滑な運営の確保を図る目的のために利用することができるものとします。
        </p>
        <p className="fs-body">第16条　反社会的勢力の排除</p>
        <p className="fs-small mb-3">
          1.当社及び会員は、現在及び将来にわたり、次の各号のいずれにも該当しないことを表明し保証するものとします。<br />
          (1) 反社会的勢力に該当すること<br />
          (2) 反社会的勢力が経営を支配していると認められる関係を有すること<br />
          (3) 反社会的勢力が経営に実質的に関与していると認められる関係を有すること<br />
          (4) 自己若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に反社会的勢力を利用していると認められる関係を有すること<br />
          (5) 反社会的勢力に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること<br />
          (6) 役員又は経営に実質的に関与している者が、反社会的勢力と社会的に非難されるべき関係を有すること<br />
          (7) 自己又は第三者をして暴力的要求、脅迫的言動、法的責任を超えた不当な要求、風説の流布・偽計・威力等による他人の信用毀損・業務妨害を行うこと<br />
          2.当社又は会員は、相手方が前項の表明・保証に違反して、前項各号の一にでも該当することが判明したときは、何らの催告をせず、本サービスの提供の停止、及び直ちに本サービスに関するすべての契約を解除することができるとともに、被った損害の賠償を請求することができるものとします。
        </p>
        <p className="fs-body">第17条　権利及び地位の譲渡等</p>
        <p className="fs-small mb-3">
          当社及び会員は、本サービスに関する一切の権利、義務及び地位を相手方の承諾なしに、譲渡、転貸、担保差入その他形態を問わず処分することはできないものとします。
        </p>
        <p className="fs-body">第18条　分離可能性</p>
        <p className="fs-small mb-3">
          本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        </p>
        <p className="fs-body">第19条　協議解決</p>
        <p className="fs-small mb-3">
          当社及び会員は、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
        </p>
        <p className="fs-body">第20条　管轄裁判所</p>
        <p className="fs-small mb-3">
          当社と会員との間における訴訟は、当社の本店所在地を管轄する地方裁判所又は簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
        <p className="fs-body">第21条　準拠法</p>
        <p className="fs-small mb-3">
          本規約の解釈は日本国の法律に準拠するものとします。
        </p>
        <p className="fs-small mb-3">
          規約制定　令和3年9月7日
        </p>
      </div>
    </>
  );
};

export default Term; 