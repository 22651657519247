export type ValidateFunction = (value: string) => string;

export const validateHandler = (
  setName: React.Dispatch<React.SetStateAction<string>>,
  setError: React.Dispatch<React.SetStateAction<string>>,
  validate: ValidateFunction
) => {
  return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const value = event.target.value;
    setName(value);
    setError(validate(value));
  };
};

export const validateName: ValidateFunction = (value: string): string => {
  // ひらがな、カタカナ、漢字のバリデーション
  const pattern = /^[ぁ-んァ-ヶ一-龠々ー]+$/;
  if (value === null) {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "ひらがな、カタカナ、漢字のみ使用できます";
  } else if (value.length > 20) {
    return "20文字以内で入力してください";
  } else {
    return "";
  }
};

// メールアドレスのバリデーション
export const validateEmail: ValidateFunction = (value: string): string => {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (value === null) {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "有効なメールアドレスを入力してください"
  } else if (value.length > 254) {
    return "メールアドレスは254文字以内で入力してください";
  } else {
    return "";
  }
};

// 半角英数字のバリデーション
export const validateAlphaNumeric: ValidateFunction = (value: string): string => {
  const pattern = /^[a-zA-Z0-9]+$/;
  return pattern.test(value) ? "" : "半角英数字のみ使用できます";
};

// 都道府県のバリデーション
export const validatePrefecture: ValidateFunction = (value: string): string => {
  const pattern = /^[一-龠々]+$/;
  if (value === null) {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "漢字のみ使用できます"
  } else if (value.length > 4) {
    return "都道府県は4文字以内で入力してください";
  } else {
    return "";
  }
};

// 市区町村のバリデーション
export const validateCity: ValidateFunction = (value: string): string => {
  const pattern = /^[ぁ-んァ-ヶ一-龠々0-9０-９\s!-/:-@[-`{-~ー－ 　]+$/;
  if (value === null) {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "ひらがな、カタカナ、数字、漢字、記号文字、スペースのみ使用できます"
  } else if (value.length > 64) {
    return "市区町村は4文字以内で入力してください";
  } else {
    return "";
  }
};

// マンション名のバリデーション
export const validateApartment: ValidateFunction = (value: string): string => {
  // 未入力も許可
  if (value === "") {
    return "";
  }
  const pattern = /^[ぁ-んァ-ヶ一-龠々0-9a-zA-Zａ-ｚＡ-Ｚ０-９!-/:-@[-`{-~ー－ 　]+$/;
  return pattern.test(value) ? "" : "使用できない文字が含まれています";
};

export const validatePhoneNumber: ValidateFunction = (value: string): string => {
  const pattern = /^[0-9]+$/;
  if (value === null) {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "半角数字のみ使用できます";
  } else if (value.length > 11) {
    return "電話番号は11桁以内で入力してください";
  } else if (value.length < 10) {
    return "電話番号は10桁以上で入力してください";
  } else {
    return "";
  }
};

export const validatePostcode: ValidateFunction = (value: string): string => {
  const pattern = /^[0-9]+$/;
  if (value === null) {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "半角数字のみ使用できます";
  } else if (value.length !== 7) {
    return "郵便番号は7桁で入力してください";
  } else {
    return "";
  }
};

export const validatePasswords = (password: string, confirm: string): string => {
  // 半角英数字のチェック
  const pattern = /^[a-zA-Z0-9]+$/;

  if (password === null || confirm === null) {
    return "この項目は入力必須です";
  } else if (password !== confirm) {
    return "パスワードが一致していません";
  } else if (!pattern.test(password) || !pattern.test(confirm)) {
    return "半角英数字のみ使用できます";
  } else if (password.length < 8 || password.length > 16) {
    return "パスワードは8文字以上、16文字以下のみ使用できます";
  } else {
    return "";
  }
};

export const validateCreditCardNumber: ValidateFunction = (value: string): string => {
  const pattern = /^[0-9]+$/;
  if (value === "") {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "半角数字のみ使用できます";
  } else if (value.length > 16 || value.length < 14) {
    return "番号の桁数に誤りがあります。";
  } else {
    return "";
  }
};

export const validateCreditCardName: ValidateFunction = (value: string): string => {
  const pattern = /^[A-Z ]+$/;
  if (value === "") {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "半角大文字アルファベットとスペースのみ使用できます";
  } else {
    return "";
  }
};

export const validateCreditCardYear: ValidateFunction = (value: string): string => {
  const pattern = /^[0-9]+$/;
  if (value === "") {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "プルダウンの中から選択してください";
  } else {
    return "";
  }
};

export const validateCreditCardMonth: ValidateFunction = (value: string): string => {
  const pattern = /^[0-9]+$/;
  if (value === "") {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "プルダウンの中から選択してください";
  } else {
    return "";
  }
};

export const validateSecuritycode: ValidateFunction = (value: string): string => {
  const pattern = /^[0-9]+$/;
  if (value === "") {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "半角数字のみ使用できます";
  } else if (value.length > 4 || value.length < 3) {
    return "番号の桁数に誤りがあります。";
  } else {
    return "";
  }
};

export const validateDeliveryCardBackNumber: ValidateFunction = (value: string): string => {
  // 入力内容が削除された場合
  if (value === "") {
    return "";
  }
  
  const pattern = /^[0-9]+$/;
  if (!pattern.test(value)) {
    return "半角英数字のみ使用できます";
  } else if (value.length !== 5) {
    return "裏面番号は5桁で入力してください";
  } else {
    return "";
  }
};

export const validateDeliveryAddress: ValidateFunction = (value: string): string => {
  const pattern = /^[ぁ-んァ-ヶ一-龠々0-9a-zA-Z０-９\-ー― 　]+$/;
  if (value === "") {
    return "この項目は入力必須です";
  } else if (!pattern.test(value)) {
    return "ひらがな、カタカナ、数字、漢字、英字のみ使用できます(改行は利用できません)";
  } else if (value.length > 100) {
    return "住所は100文字以内で入力してください";
  } else {
    return "";
  }
};

export const validateSearchDeliveryTime: ValidateFunction = (value: string): string => {
  const pattern = /^[0-9]+$/;
  if(value === ""){
    return "";
  }
  else if (!pattern.test(value)) {
    return "半角数字のみ使用できます";
  } else if (value.length !== 14) {
    return "14桁で入力してください";
  } else {
    return "";
  }
};