import React, { useEffect, useState } from 'react';
import { Products } from '../../utils/getData/PossesionProducts';
import { FormValues } from './Delivery'
import { validatePostcode, validateDeliveryAddress, validateDeliveryCardBackNumber } from '../../utils/validate/Validate';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

type DeliveryFormProps = {
  formValues: FormValues,
  tickets: number,
  productData: Products | {},
  setFormValues: React.Dispatch<React.SetStateAction<FormValues>>,
  setStatus: React.Dispatch<React.SetStateAction<string>>
}

export const DeliveryForm: React.FC<DeliveryFormProps> = ({ formValues, tickets, productData, setFormValues, setStatus }) => {
  const [postcodeError, setPostcodeError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [backNumError, setbackNumError] = useState(false);
  interface backNumValidateResults {
    [productName: string]: {
      [productVariationId: number]: {
        [possessionIndex: number]: string;
      };
    };
  }

  const [backNumValidateResults, setbackNumValidateResults] = useState<backNumValidateResults>({} as backNumValidateResults);

  var printUrls: Object;
  if (typeof process.env.REACT_APP_PRINT_URL === "string") {
    printUrls = JSON.parse(process.env.REACT_APP_PRINT_URL);
  } else {
    printUrls = [];
  }

  const handleChangePostcode = (value: string) => {
    const result = validatePostcode(value);
    if (result !== "") {
      setPostcodeError(result);
    } else {
      setPostcodeError("");
      setFormValues(prev => ({ ...prev, postcode: value }))
    }
  };

  const handleChangeAddress = (value: string) => {
    const result = validateDeliveryAddress(value);
    if (result !== "") {
      setAddressError(result);
    } else {
      setAddressError("");
      setFormValues(prev => ({ ...prev, address: value }))
    }
  };

  const handleChangeBackNum = (productName: string, productVariationId: number, possessionIndex: number, value: string) => {
    const result = validateDeliveryCardBackNumber(value);
    setbackNumValidateResults(prev => {
      const updatedProducts = { ...prev };
      if (!updatedProducts[productName]) {
        updatedProducts[productName] = {};
      }
      if (!updatedProducts[productName][productVariationId]) {
        updatedProducts[productName][productVariationId] = {};
      }
      // possessionIndexで指定された位置にバリデーション結果を設定
      updatedProducts[productName][productVariationId][possessionIndex] = result;
      return updatedProducts;
    });

    setFormValues(prev => {
      // prev.productsを複製してから更新
      const updatedProducts = { ...prev.products };
      if (!updatedProducts[productName]) {
        updatedProducts[productName] = {};
      }
      if (!updatedProducts[productName][productVariationId]) {
        updatedProducts[productName][productVariationId] = [];
      }

      // 指定されたproductVariationIdとpossessionIndexでProductオブジェクトを見つけ、noteを更新
      // ここではProductの配列が既に存在することを期待している
      const targetProducts = updatedProducts[productName][productVariationId];
      if (targetProducts[possessionIndex]) {
        targetProducts[possessionIndex] = { ...targetProducts[possessionIndex], note: value };
      } else {
        // possessionIndexに対応するProductが存在しない場合の処理、適切な処理をここに追加
        console.error("Specified possessionIndex does not exist.");
      }

      // prev全体を更新して返す
      return {
        ...prev,
        products: updatedProducts,
      };
    });
  };


  useEffect(() => {
    let errorFound = false;

    Object.values(backNumValidateResults).forEach(productVariations => {
      Object.values(productVariations).forEach(variation => {
        Object.values(variation).forEach(note => {
          if (note !== "") { // 空文字列でない値をチェック
            errorFound = true;
          }
        });
        if (errorFound) return; // 内側のループから抜ける（forEachではbreakに相当する操作はreturnを使う）
      });
      if (errorFound) return; // 中間のループから抜ける
    });

    setbackNumError(errorFound);
  }, [backNumValidateResults]);

  const handleConfirm = () => {
    setStatus("confirm");
  };

  return (
    <>
      <p className="topTitle">発送手続き</p>
      <div className="px-1 mainSection">
        <p className="fs-caption m-2">
          発送するオンサイトカードにはそれぞれの裏面をお手持ちの画像をプリントすることができます。<br />
          ※数字を入力しない場合、裏面にはチームロゴが印刷されます。<br />
          また、番号が間違っていたり、アップロードが正常に完了していない場合やチームのサイトとカードが一致していない認証番号の場合もロゴカードが発送されますので、ご注意ください。<br />
          <br />
          裏面の画像設定方法<br />
          ①「裏面の画像を設定する」ボタンで外部サイトへ移動<br />
          ②お好みの画像をアップロード<br />
          ③アップロード後に表示される5桁の画像番号を下記入力欄に入力
        </p>
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            裏面の画像を設定する
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {
              Object.entries(printUrls).map(([key, value], index) => (
                <Dropdown.Item href={value} key={index}>{key}</Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
        <div className="px-2 pb-3 border-bottom">
          {/* ポイントが足りない場合 */}
          {tickets !== null && (
            tickets >= 1 ? (
              <p className="fs-description my-1">現在の所持チケット： {tickets}</p>
            ) : (
              <p className="fs-description my-1 text-danger">
                現在の所持チケット： {tickets}<br />チケットが足りないため発送を利用できません。<br />チケットをご購入ください。
              </p>
            )
          )}
        </div>

        {Object.keys(productData).length === 0 && productData.constructor === Object ?
          <>
            <div>
              <p className='text-danger text-bold fs-5'>
                現在所持してるカードはありません。<br />
                カードを手に入れることで発送を行えます。
              </p>
            </div>
          </> :
          <>
            <form onSubmit={handleConfirm}>
              <div className='overflow-auto h-500 border-bottom'>
                {Object.entries(productData).map(([productName, productVariations]) => (
                  <div key={productName} className="mx-2 text-center">
                    {/* TODO プロダクトごとにproductnameの横に裏面用サイトリンクのボタンを設置 */}
                    <p className="fs-description my-2">{productName}</p>
                    {productVariations.map((product) => (
                      [...Array(product.possession)].map((_, possessionIndex) => (
                        <div key={`${product.variation_id}-${possessionIndex}`}>
                          <div className='flex-center my-3' key={`${product.variation_id}-${possessionIndex}`}>
                            <div className='max-w-220 mx-4'>
                              <img className="card-shadow my-1 h-150" src={`/image/${product.team_name}/${product.product_id}/${String(product.variation_id).padStart(8, '0')}.jpg`} />
                              <p className="fs-description my-2">
                                {product.variation_name} <br /> {product.rarity_name}
                              </p>
                            </div>
                            <div className='mx-2 max-w-150'>
                              {
                                backNumValidateResults[productName]?.[product.variation_id]?.[possessionIndex] !== undefined && (
                                  <div className='text-danger'>{backNumValidateResults[productName][product.variation_id][possessionIndex]}</div>
                                )
                              }

                              <p className="fs-description mt-4 my-1 mx-auto">裏面画像番号：</p>
                              <input
                                className="form-control mx-auto my-1"
                                type="text"
                                defaultValue={formValues.products[productName][product.variation_id][possessionIndex].note || ''}
                                onBlur={e => handleChangeBackNum(productName, product.variation_id, possessionIndex, e.target.value)}
                              />
                            </div>
                            <input type="hidden" value={product.variation_id} />
                            <input type="hidden" value={product.variation_name} />
                            <input type="hidden" value={product.rarity_name} />
                          </div>
                        </div>
                      ))
                    ))}
                  </div>
                ))}
              </div>
              <div className='d-table mx-auto my-2'>
                <p className="fs-description my-1">郵便番号</p>
                <input
                  className="form-control w-40 min-w-350"
                  id="postcode"
                  type="text"
                  name="postcode"
                  defaultValue={formValues.postcode}
                  onBlur={e => handleChangePostcode(e.target.value)}
                />
                <div className='text-danger'>{postcodeError}</div>
                <p className="fs-description my-1">住所</p>
                <textarea
                  id="address"
                  rows={4}
                  className="form-control mb-2 w-80 min-w-350"
                  name="address"
                  defaultValue={formValues.address}
                  onBlur={e => handleChangeAddress(e.target.value)}
                />
                <div className='text-danger'>{addressError}</div>
                <div className='d-flex mb-3 justify-content-evenly'>
                  {tickets !== null && (
                    tickets >= 1 ? (
                      addressError === "" && postcodeError === "" && !backNumError ? (
                        <Button onClick={handleConfirm} variant="primary">確認画面へ</Button>
                      ) : (
                        <div className='text-danger'>修正が必要な項目があります。</div>
                      )
                    ) : (
                      <div className='text-danger'>チケットを所持していないため発送できません。</div>
                    )
                  )}
                </div>

              </div>
            </form>
          </>
        }
      </div >
    </>
  );
};