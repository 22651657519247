import axios from "axios";
import { ProductStock } from "../../utils/getData/ProductStock";
import { ProductVariations } from "../../utils/getData/Album";

const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

interface ProductItemProps {
  team: string;
  product_code: string;
  productValiation_id: number;
  rarity_name: string;
  variation_name: string;
  possession: number;
  is_possession: boolean;
}

export const ProductItem = ({ team, product_code, productValiation_id, rarity_name, variation_name, possession, is_possession }: ProductItemProps) => {

  const imgSrc = `/image/${team}/${product_code}/${String(productValiation_id).padStart(8, '0')}.jpg`;

  return (
    <div className="text-center w-25" key={productValiation_id}>
      <img className={`${is_possession ? '' : 'mosaic'}`} src={imgSrc} />
      <p className="my-1">[{rarity_name}] {variation_name}</p>
      <p className="my-1 fs-caption">
        所持数：{possession}
      </p>
    </div>
  );
};